import React, {useLayoutEffect, useState, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {graphql} from "gatsby";

import Layout from "../components/layout";
import { PaginationFooter } from '../components/sections';
import { DisplayHeader, WineryList } from '../components/composites';
import { getFilteredWineries, setExperienceArray } from "../utils";

import './vineyards.scss'


const VindyardsPage = ({data, location}) => {
    const {
        directus: {
            wineries
        }
    } = data;
    const { search } = location;

    const countryName = 'All Vineyards';
    const listRef = useRef(null);
    const wineryLength = wineries.length;
    const params = new URLSearchParams(search);
    const pageNumber = Number(params.get("page") ?? 1);
    const startWineryDisplay = (pageNumber - 1) * 18;
    const endWineryDisplay = pageNumber * 18;

    const primaryWinerySlug = wineries[0].slug;
    const secondaryWinerySlug = wineryLength > 1 ? wineries[1].slug : null;

    const primaryWinery = wineries.find(({slug}) => slug === primaryWinerySlug)
    const secondaryWinery = wineries.find(({slug}) => slug === secondaryWinerySlug)

    const filterPreppedArray = useMemo(() => setExperienceArray(wineries), [wineries])
    const [filteredWineries, setFilteredWineries] = useState(wineries);
    const [wineriesToDisplay, setWineriesToDisplay] = useState(filteredWineries.slice(startWineryDisplay, endWineryDisplay));

    useLayoutEffect( () => {
        const filteredResults = getFilteredWineries(location, filterPreppedArray)
        setFilteredWineries(filteredResults)
        setWineriesToDisplay(filteredResults.slice(startWineryDisplay, endWineryDisplay))
    }, [filterPreppedArray, search, location])

    return(
        <Layout className='country-template'
                title='All Vineyards | Explore Wineries Worldwide'
                currentPage='vineyards'
                location={location}
                description='Checkout all our vineyards'
                image={null}
        >
            <DisplayHeader
                title={countryName}
                wineryCount={filteredWineries.length}
                location={location}
                hiddenFilters={['Grape']}
            >
                <div ref={listRef}/>
                <WineryList wineries={wineriesToDisplay} primaryFeatureWinery={primaryWinery} secondaryFeatureWinery={secondaryWinery} search={search} />
            </DisplayHeader>

            <PaginationFooter setDataToDisplay={setWineriesToDisplay} data={filteredWineries} scrollToRef={listRef} pageNumber={pageNumber} location={location}/>
        </Layout>
    )
}

VindyardsPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

VindyardsPage.defaultProps = {

}

export const VineyardQuery = graphql`
  query {
    directus {
      wineries(
        limit: -1
        sort: ["-vind_score"]
       filter: {status: {_eq: "published"}}
      ) {
         additional_images {
            directus_files_id {
              filename_disk
              }
         }
         slug
         featured_image {
            filename_disk
         }
         google_total_reviews
         google_rating
         name
         country {
            name
            slug
         }
         region
         summary
         accommodation
         cellar_door
         events
         restaurant
         tours
         tastings
         tags {
            tags_id {
              slug
            }
          }  
       }
    }
  }
`

export default VindyardsPage